.createClass{
    form{
        display: flex;
        flex-direction: column;
        gap:1em;
        .timePickerSection{
            display: flex;
            justify-content: space-evenly;
            align-items: flex-start;
            gap: 2em;
            // width: 100%;
                div{
                    max-width: 10.5em;
                }
                span{
                    display: none;
                }
        }
        .datePickerSection{
            max-width: 10.5em;
            p{
                margin: 0;
            }
            input{
                outline: none;
                color: inherit;
                padding: 0;
                font: inherit;
                cursor: pointer;
                outline: inherit;
                padding: 0.5rem 0.5rem;
                font-size: 15px;
                border: 2px solid var(--background-medium-grey);
                border-radius: 4px;
                background-color: white;
                margin-bottom: 0.7em;
            }
            
        }
        .startEndDatePickersDiv{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1em;
            .seriesDatePickerDiv{
                margin: 0 0 0.4em 0;
                svg{
                    display: none;
                }
                p{
                    text-align: left;
                    margin: 0.6em 0 0.2em 0;
                }
                input{
                    outline: none;
                    color: inherit;
                    padding: 0;
                    font: inherit;
                    cursor: pointer;
                    outline: inherit;
                    padding: 0.7rem 0.5rem;
                    font-size: 15px;
                    border: 2px solid var(--background-medium-grey);
                    border-radius: 4px;
                    background-color: white;
                }
            }
        }
        .toggleDiv{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            p{
                margin: 0 5em 0 0 ;
            }
        }
        .radio{

            display: flex;
            flex-direction: column;
            justify-content: center;
            // align-items: flex-start;
            text-align: left;
            gap: 0.5em;
            .radioButtons{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                div{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap:0.5em;
                    input{
                        margin: 0.2em 0em;
                    }
                    
                }
                
            }
        }
    }
}



.halfInput{
    display: flex;
    background-color: white;
    margin: 0em 0 0 0;
    width:auto;
}

.descriptionInput{
    text-align: left;

}
.twoHalfDivs{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 2;
    gap: 1em;
    div{
        max-width: 9.5em;
        width: 100%;
    }
    
}








.createButton{
    display: block;
    cursor: pointer;
    padding: 0.7em 0;
    text-align: center;
    font-size: 1em;
    color: white;
    width: 100%;
    border-radius: 0.4em;
}
.createButton:hover{
    padding: 0.7em 0;
    border: 2px solid var(--background-dark-grey);
    color: var(--text-dark-grey);
    cursor: pointer;
    
}

@media only screen and (min-width: 600px) {
    .createClass{
        max-width: 25em;
        margin:auto;
        padding:0 2em;
        position: relative;
    }
    .datePickerSection{
        max-width: 9.5em;
    }
  }
