.App {
  text-align: center;
  color:black;
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.content{
  flex:10;
  overflow-y: scroll;
  padding:1em;
}