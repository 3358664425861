.appHeader{
    display: flex;
    align-items: center;
    padding: 0.5em 3em;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .yogamitraBranding{
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}

.desktopMenu{
    .desktopMenuBackground{
        width: 100vw;
        height: 100vh;
        position: absolute;
        background-color: none;
        top:0;
        left: 0;
        z-index: 1000;
    }
    .desktopMenuButton{
        display: flex;
        justify-content: center;
        align-items: center;
        gap:1em;
        .menubarUserName{
            font-size: 1.2em;
        }
        div{
            background-color: var(--background-light-grey);
            color:var(--text-medium-grey);
            width: 2em;
            height: 2em;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
    .menu{
        position: absolute;
        padding: 0.5em;
        background-color: var(--text-light);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap:1em;
        max-width: 20em;
        min-width: 10em;
        text-align: left;
        right:3em;
        top:3em;
        z-index: 10000;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        div{
            cursor: pointer;
            display: flex;
            justify-content: left;
            align-items: center;
            width: 100%;
            a{
                width: 100%;
                color:var(--text-medium-grey);
                text-align: left;
            }
        }
        div:hover{
            a{
                color: var(--text-dark-grey);
            }
        }
    }
}


@media only screen and (max-width:540px) {
    .appHeader{
        padding:0.5em 0.5em;
    }
    .desktopMenuButton{
        .menubarUserName{
            font-size: 0.5em;
        }
        .menu{
            right:1em;
        }
    }   
}