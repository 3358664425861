.userSignIn{
    max-width: 23em;
    margin:auto;
    // padding:0 2em;
    position: relative;
}

.inputFields{
    display: flex;
    flex-direction: column;
}
.signInMain{
    h1{
        font-size: 2em;
        font-weight: bold;
    }
    h2{
        font-weight: 600;
        font-size: 24px;
        margin: 0 0 30px 0;
    }
    p{
        font-weight: 600;
        font-size: 17px;
    }
    

}
.signInButtonDiv{

    button{
        width: 100%;
        margin: 10px 0 0 0;
    }
}

.forgotPassword{
    color: var(--text-blue);    
    text-align: left;
    cursor: pointer;
    width: fit-content;
    margin:0.5em 0;
}

.info{
    text-align: left;
    margin:30px 0 0 0;
    font-size: 0.8em;
    span{
        color: var(--text-blue);
        cursor: pointer;
        text-decoration: underline;
    }
}

.orComponent{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1em 0;
    div{
        height: 1.5px;
        flex:5;
        width: 100%;
        background-color:  var(--background-regular-grey);
    }
    p{
        flex:1;
    }
}

.signInText{
    margin:2em 0;
    span{
        color: var(--text-blue);
        cursor: pointer;
    }
}

.showPasswordDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .inputLabelSPB{
        display: flex;
        gap: 0.5em;
    }
}