.appTextArea{
  .growWrap {
    display: grid;
  }
  .growWrap:after {
    content: attr(data-replicated-value) " ";
    white-space: pre-wrap;
    visibility: hidden;
  }
  .growWrap > textarea {
    resize:vertical;
    overflow: hidden;
    height: 15vh;
    border: 1px solid var(--background-light-grey);
    border-radius: 0.3em;
  }
  .growWrap > textarea,
  .growWrap:after {
    border: 1px solid var(--background-dark-grey);
    padding: 0.5rem;
    font: inherit;
    grid-area: 1 / 1 / 2 / 4;
  }
}

  