.statCard{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 17px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    // margin: 1em 0;
    h2{
        // margin: 0.3em 0 0 0;
        font-weight: bold;
        margin:0;
    }
    p{
        margin: 0 0 1em 0;
    }
}