
:root {
	--text-light:#fff;
	--text-dark: #1D1D1F;

	/** Primary **/
	--text-light-heading:#CCDAE7;
	--text-light-heading-fade:#EDEAEA;
	--text-light-grey:#F3F3F3;
	--text-regular-grey:#CACACA;
	--text-medium-grey:#7E7E7E;
	--text-dark-grey:#393939;
    --text-black:#1D1D1F;
	/** Secondary **/
	--text-green:#27BE73;
	--text-red:#FF122E;
	--text-blue:#407BFF;

	/** Background **/
	--background-light-heading:#CCDAE7;
	--background-light-heading-fade:#EDEAEA;
	--background-light-grey:#F3F3F3;
	--background-regular-grey:#CACACA;
	--background-medium-grey:#7E7E7E;
	--background-dark-grey:#393939;
    --background-black:#1D1D1F;

	/** red background **/
	--background-red:#FF122E;

	/** green background **/
	--background-green:#27BE73;
 }