.editUserProfile{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    p{
        font-size: 1.2em;
        font-weight: 600;
    }
    .title{
        display: flex;
        gap: 1em;
        h2{
            margin: 0;
            font-size: 2.5em;
            font-weight: 600;
            img{
                cursor: pointer;

            }
        }

    }
    .userProfileForm{
        width: 100%;
        
        img{
            width: 100%;
            max-width: 27em;
            margin: 2em 0;
        }
        form{
            display: flex;
            flex-direction: column;
            gap:1em;
            .fullInput{
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }
}







.createButton{
    display: block;
    cursor: pointer;
    padding: 0.93em 0;
    text-align: center;
    font-size: 1em;
    color: white;
    width: 100%;
    border-radius: 0.4em;
    .appPrimaryFillButton{
        height: 4em;
    }

}
.createButton:hover{
    padding: 0.5em 0;
    border: 2px solid var(--background-dark-grey);
    color: var(--text-dark-grey);
    background-color: white;
    cursor: pointer;
    padding: 0.93em 0;
    
}
@media only screen and (min-width: 540px) and (max-width: 1140px) {
    .userProfileForm{
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        img{
            width: 100%;
        }
        form{
            align-items: center;
            .fullInput{
                min-width: 23em;
                gap: 1em;
            }
        }
    }
}




@media only screen and (min-width: 1140px) {
    .editUserProfile{
        margin:auto;
        padding:0 4em;
        position: relative;
        .userProfileForm{
            display: flex;
            flex-direction: row-reverse;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            gap: 1em;
            form{
                align-items: center;
                .fullInput{
                    min-width: 23em;
                    gap: 1em;
                }
            }
        }
    }
  }
