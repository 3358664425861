.classDates{
    margin: 2em 1em;
}

.dropdownComponent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    input{
        border-radius: 5px;
        width: 100%;
    }
    select{
        width: 100%;
        height: 7vh;
        border: 2px solid black;
        font-size: 1.3em;
    }

    .dropdownContent{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    
}
.seriesDateTable{
    width: 100%;
    table {
        width: 100%;
        th{
            border-left: 1px solid black;
            border-bottom: 1px solid black;
            border-collapse: collapse;
            height: 3em;
            background-color: rgb(246, 245, 245);
            position: sticky;
            top: 0;
            z-index: 1;
            box-shadow: 1px 1px 1px 1px black;
        }
        td{
            border-left: 1px solid black;
            border-collapse: collapse;
            height: 2.5em;
        }
        .evenRow {
            background-color: #ffffff;
          }
          
          .oddRow {
            background-color: var(--background-light-grey);
          }
      }
      .editAndAttendaceDiv{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 1em 0;
        label{
            font-size: 1em;
            input{
                margin: 0 1em;
            }
        }
        .saveButtonDiv{
            max-width: 5em;
            width: 100%;
        }
    }
     
}

.tableDateComp{
    width: 100%;
    margin: 1em 0 0 0;
    .tableDateCompHeader{
        margin: 1em 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h1{
            font-size: 1.4em;
            font-weight: 600;
        }
        .editAndAttendaceDiv{
            display: flex;
            flex-direction: row;
            gap: 2em;
            .saveButtonDiv{
                min-width: 5.5em;
                width: 100%;
            }
        }
    }
    
}


@media only screen and (min-width: 720px) {
    .classDates{
        padding: 0 27%;
    }
}