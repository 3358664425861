// .classCardComponent{
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     max-width: 24em;
//     border: 2px solid var(--background-regular-grey);
//     border-radius: 10px;
//     max-height: fit-content;
//     width: 100%;
//     margin: 1em 0em;
    
//     .rightDetails{
//         width: 70%;
//         display: flex;
//         flex-direction: column;
//         justify-content: space-between;
//         height: 100%;
        
//         h2{
//             text-align: left;
//             font-size: 1.1em;
//             // margin: 0.5em 0 0 0.5em;
//             position: relative;
//             bottom: 1em;
//             left: 1em;
//             font-weight: bold;
//         }
//         div{
//             display: flex;
//             flex-direction: row;
//             justify-content: flex-start;
//             align-items: flex-start;
//             position: relative;
//             left: 1em;
//             // padding: 0.4em 0.8em;
//             p{
//                 margin: 0 0.5em ;
//                 font-size: 0.9em;
//             }
//             h3{
//                 margin: 0 0.5em ;
//                 text-align: left;
//             }
//         }
//     }
//     .leftDetails{
//         background-color: var(--text-light-grey);
//         width: 30%;
//         border-radius: 0px 10px 10px 0;
//         display: flex;
//         flex-direction: column;
//         justify-content: space-between;
//         align-items: center;
//         border-left: none;
//         border-right: none;
//         .level{
//             border-radius: 0px 10px 0px 0;
//             width: 100%;
//             height: 4vh;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             p{
//                 font-size: 0.9em;
//                 font-weight: 600;
//             }
//             &.beginnerLevel{
//                 background-color: #C1E1FF;
//             }
//             &.intermediateLevel{
//                 background-color: #39E4C7;
//             }
//             &.advancedLevel{
//                 background-color: #C5BBFA;
//             }
//         }
//         .priceDiv{
//             h4{
//                 font-size: 2em;
//                 margin: 1em 0;
//                 font-weight: bold;
//             }
//         }
//         .viewDetails{
//             margin: 0.8em 0;
//             cursor: pointer;
//             color: black;
//             h4{
//                 font-weight: 600;
//             }
//         }
//     }
// }



// @media only screen and (min-width: 1140px) {
//     .classCardComponent{
//         min-width: 30em;
//     }
// }

.classCard{
    display: flex;
    flex-direction: row;
    max-width: 24em;
    border: 2px solid var(--background-regular-grey);
    border-radius: 10px;
    width: 100%;
    .classCardSubcomponent1{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        width: 70%;
        padding: 1em 0;
        gap: 1em;
        h2{
            margin: 0em 0.5em 0 0.7em;
            text-align: left;
            font-size: 1.1em;
            font-weight: bold;
        }
        div{
            display: flex;
            flex-direction: row;
            text-align: left;
            img{
                margin: 0em 0.9em;
            }
        }
    }
    .classCardSubcomponent2{
        width: 30%;
        background-color: var(--text-light-grey);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-radius: 0px 10px 10px 0;
        .level{
            border-radius: 0px 10px 0px 0;
            width: 100%;
            height: 4vh;
            display: flex;
            justify-content: center;
            align-items: center;
            p{
                font-size: 0.9em;
                font-weight: 600;
            }
            &.beginnerLevel{
                background-color: #C1E1FF;
            }
            &.intermediateLevel{
                background-color: #39E4C7;
            }
            &.advancedLevel{
                background-color: #C5BBFA;
            }
        }
        .price{
            h4{
                font-size: 2em;
                font-weight: bold;
            }
        }
        .viewDetails{
            margin: 0.8em 0;
            cursor: pointer;
            color: black;
            h4{
                margin: 0.8em 0;
                font-weight: 600;
            }
        }
    }
}

@media only screen and (min-width: 1140px) {
    .classCard{
        min-width: 30em;
    }
}