.appInputField{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:0px;
    margin: 0 0 0.7em 0;
    
    p{
        text-align: left;
        margin:0;
    }
    input{
        // width: auto;
        outline: none;
        color: inherit;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        padding: 0.7rem 0.5rem;
        font-size: 15px;
        border: 2px solid var(--background-medium-grey);
        border-radius: 4px;
    }
    .errorTag{
        color: red;
        font-size: 1em;
        margin: 0;
    }
}