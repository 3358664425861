.requestedClassPage{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .switchButtons{
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 21em;
        .selectedButton{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 3em;
            cursor: pointer;
            background-color: var(--text-light-heading-fade);
            color: var(--background-black);
            box-shadow: 0px 2px 0px 0px black;

        }
        .unselectedButton{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            height: 3em;
            cursor: pointer;
            background-color: var(--text-light-grey);
        }
}
    .classinfo{
        margin-top: 2em;  
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;
        align-items: flex-start;
        gap: 1em;    
    }
    .classesList{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;
        align-items: flex-start;
        margin-bottom: 1em;
        gap: 1em; 
        background-attachment: red;
    }
}
.classDataComp{
    width: 100%;
    .classesList{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;
        align-items: flex-start;
        gap: 1em;
        margin-bottom: 10em;
    }
}