.home{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1{
        margin: 0.5em 0;
        font-size: 2em;
        font-weight: bold;
    }
    h3{        
        margin: 0.5em 0 1.5em 0;
        font-size: 32px;
        font-weight: 600;

    }
    .buttonDiv{
    margin: 2em 0;
    margin-top:0;
    min-width: 10em;
    }
    .imgDiv{
        width: 100%;
        img{
            width: 100%;
        }
    }

    
    .pricingSection{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
    }
}


.homePage{
    .cardTitle{
        font-size: 52px;
        margin: 0.5em 0;
        font-weight: bold;
        margin: 1.5em 0 0.5em 0;
    }
    .benefits{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: left;
        margin: 0 0 3em 0;
        // padding: 38em;
        .benefitsLine{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width:15em ;
        }
        img{
            width: 0.8em;
        }
        p{
            margin: 0.5em;
            font-weight: 600;
            font-size: 17px;
        }
        
    }
    
}
@media only screen and (max-width: 540px) {
    .homePage {
        .cardTitle{
            font-size: 2.5em;
        }
    }
    }
.pricingCards{
    .priceCard{
        border:  1px solid black;
        border-radius: 20px;
        min-width: 20em;
        position: relative;
        margin: 2em 0;
        p{
            font-size: 2em;
            font-weight: bold;
            margin: 0.5em 0;
        }
        .cardTitle{
            font-size: 2em;
            margin: 0.5em 0;
        }
        .oldPrice{
            color: grey;
            margin: 2em 0 0 0;
            p{
                font-size: 2em;
                margin: 0;
            }
        }
        .newPrice{
            display: flex;
            justify-content: center;
            align-items: baseline;
            gap: 0.5em;
            p{
                font-size: 4em;
                margin: 0;
                font-weight: bold;
            }
            h3{
                color: grey;
                font-size: 1.5em;
            }
        }
        .blackLine{
            background-color: grey;
            height: 1px;
            margin: 1em 1.5em;
        }
        .cardButtonDiv{
            margin: 4em 1.5em 4em 1.5em;
        }
    }

}

.premiumPricingCards{
    border: 4px solid #9B37F2;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 20px ;
    min-width: 20em;
    position: relative;
    margin: 1em 0;
    background-color: #9B37F2;
    p{
        font-size: 2em;
        font-weight: 600;
        margin: 0.5em 0;
        color: white;
    }
    .premiumOffer{
        background-color: white;
        border-radius: 20px 20px 0px 0px;
        min-height: 7em;
        p{
            color: #9B37F2;
            border-bottom: 1px solid white;
            font-size: 2em;
            margin: 0em 0 0em 0;
        }
        h3{
            font-size: 3em;
            margin: 0em 0 0em 0;
            font-weight: bold;
        }
    }
    .oldPrice{
        color: grey;
        margin: 2em 0 0 0;
        p{
            font-size: 2em;
            margin: 0;
            color: white;
        }
    }
    .newPrice{
        display: flex;
        justify-content: center;
        align-items: baseline;
        gap: 0.5em;
        color: white;
        p{
            font-size: 4em;
            margin: 0;
            font-weight: bold;
        }
        h3{
            color: grey;
            font-size: 1.5em;
            color: white;
        }
    }
    .cardButtonDiv{
        margin: 4em 1.5em 4em 1.5em;
        .appPrimaryButton{
            border: 0 solid white;
        }

    }
}

@media only screen and (max-width: 1140px) {
    .pricingSection{
        flex-wrap: wrap;
    }
}

@media only screen and (max-width:600px){
    .home{
        
        h3{
            font-size: 20px;
            font-weight: 500;
        }
    }
}


@media only screen and (min-width: 600px) {
    .home{
        h1{
            font-size: 52px;
        }
        
        h3{
            font-size: 32px;
        }
    }
    .pricingSection{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }
    .priceCard{
        min-width: 25em;
    }
    .premiumPricingCards{
        .cardButtonDiv{
            margin: 4em 2em 4em 2em;
        }
    }
  }

