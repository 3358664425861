.createClassScheduler{
    margin: 0;
    width: 100%;
    .fillTheseDetails{
        font-size: 2em;
        font-weight: 600;
    }
    .datePickerDiv{
        margin: 0 0 0.4em 0;
        svg{
            display: none;
        }
        p{
            text-align: left;
            margin: 0.6em 0 0.2em 0;
        }
        input{
            outline: none;
            color: inherit;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
            padding: 0.7rem 0.5rem;
            font-size: 15px;
            border: 2px solid var(--background-medium-grey);
            border-radius: 4px;
            background-color: white;
        }
    }
    .startEndDatePickersDiv{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1em;
        .seriesDatePickerDiv{
            margin: 0 0 0.4em 0;
            svg{
                display: none;
            }
            p{
                text-align: left;
                margin: 0.6em 0 0.2em 0;
            }
            input{
                outline: none;
                color: inherit;
                padding: 0;
                font: inherit;
                cursor: pointer;
                outline: inherit;
                padding: 0.7rem 0.5rem;
                font-size: 15px;
                border: 2px solid var(--background-medium-grey);
                border-radius: 4px;
                background-color: white;
            }
        }
    }
    .startEndTimePickers{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1em;
    }
    button{
        width: 100%;
    }
}

@media only screen and (min-width: 600px) {
    .createClassScheduler{
        max-width: 23em;
        margin:auto;
        position: relative;
    }
  }