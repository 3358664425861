.classDateEdit{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1{
        font-size: 2em;
        margin: 0 0 0.5em 0 ;
        font-weight: 600;
    }
    .dateAndTimePickerComponent{
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.5em;
        margin: 0.5em;
        span{
            display: none;
        }
        input{
            border-radius: 6px;
        }
        h3{
            font-size: 1.2em;
            font-weight: 600;
        }
        .saveButtonRightAliignment{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            div{
                width: 100%;
            }
        }
        .startEndTimePickers{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1em;
        }

    }
    .backAndSaveButtonDiv{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1em;
        margin: 1em;
        div{
            min-width: 12.2em;
            width: 100%;

        }
    }
}


@media only screen and (max-width: 600x) {
    .backAndSaveButtonDiv{
        div{
            min-width: 10.2em;
            width: 100%;

        }
    }
}
@media only screen and (min-width: 1140px) {
    .classDateEdit{
        padding: 1em 20em;
    }
}