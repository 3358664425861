.createClass{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1{
        font-size: 3em;
        font-weight: bold;
    }
    img{
        margin: 1.5em 0;
    }
    .createButtonDiv{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 1em;
        .singleButtonDiv{
            margin: 0.5em 0;
            width: 100%;
            .classDefinition{
                border: 2px solid var(--background-dark-grey);
                background-color: white;
                padding: 0.5em 0;
                cursor: pointer;
                color: var(--text-dark-grey);
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                position: relative;
                height: 3em;
                width: 100%;
                border-radius: 8px;
                h3{
                    margin: 0;
                }
                p{
                    margin: 0;
                    font-size: 0.7em;
                }
            }
            .classDefinition:hover{
                background-color: var(--background-dark-grey);
                color:var(--text-light-heading-fade)
            }
        }
    }
}
@media only screen and (max-width: 600px) {
    .createClass{
        h1{
            font-size: 1.7em;
        }
    }
}
@media only screen and (min-width: 600px) {
        .singleButtonDiv{
            max-width: 17em;
        }
    }