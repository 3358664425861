
.classDetailsMain{
    display: flex;
    flex-direction: column;
    gap: 1em;
    .desktopViewDiv{
        display: flex;
        flex-direction: column;
        .classInformation{
            display: flex;
            flex-direction: column;
        }
        .studentsTableSection{
            display: flex;
            flex-direction: column;
        }
    }
}



.toggleButtonSection{
    background-color: var(--background-light-grey);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.7em 0;
    div{
        display: flex;
        justify-content: center;
        align-items: center ;
        img{
            margin: 0 0.5em;
            cursor: pointer;
        }
    }

}
.classCardDetails{
    display: flex;
    justify-content: space-between;
    padding: 1em;
    .overviewCard{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 1em 0 0 0;
        border-radius: 11%;
        min-width: 7em;
        max-height: 15vh;
        width: fit-content;
        box-shadow: 1px 10px 10px 5px var(--background-light-grey);
        .levelBg{
            display: flex;
            justify-content: center;
            align-items: flex-end;
            background-color: #CAF1B2;
            border-radius: 12px 12px 0 0;
            width: 100%;
            &.beginnerLevel{
                background-color: #C1E1FF;
            }
            &.intermediateLevel{
                background-color: #39E4C7;
            }
            &.advancedLevel{
                background-color: #C5BBFA;
            }
        }
        p{
            font-size: 1em;
        }
        h2{
            margin: 0em;
            font-size: 2em;
        }
    }
    .classDetails{
        display: flex;
        flex-direction: column;
        background-color: white;
        p{
            text-align: left;
            font-size: 2em;
            margin: 0;
        }
        div{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0.3em 0;
            img{
                margin: 0 0.5em 0 0;
            }
            p{
                margin: 0;
                font-size: 0.9em;
                font-weight: 600;
            }
        }
    }
}

.studentNameSection{
    table {
        width: 100%;
        th{
            border-left: 1px solid black;
            border-bottom: 1px solid black;
            border-collapse: collapse;
            height: 3em;
            background-color: var(--text-light-heading-fade);
        }
        td{
            border-left: 1px solid black;
            border-collapse: collapse;
            height: 2.5em;
        }
      }
}

.checkboxButton{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .checkboxes{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0 1em;
    }
    .checkboxSaveButton{
        padding: 1em 0;
        max-width: 6em;
        width: 100%;
    }
}
.tableDateComp{
    margin: 2em 0 0 0;
    .tableDateCompHeader{
        margin: 1em 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h1{
            font-size: 1.4em;
            font-weight: 600;
        }
        .editAndAttendaceDiv{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2em;
            img{
                cursor: pointer;
                width: 2.5em;
            }
            .markAttendanceDiv{
                width: 8.5em;
            }
        }
    }
    .seriesDateTable{
        max-height: 10em; 
        overflow-y: auto;
        table {
            width: 100%;
            th{
                border-left: 1px solid black;
                border-bottom: 1px solid black;
                border-collapse: collapse;
                height: 3em;
                background-color: rgb(246, 245, 245);
                position: sticky;
                top: 0;
                z-index: 1;
                box-shadow: 1px 1px 1px 1px black;
            }
            td{
                border-left: 1px solid black;
                border-collapse: collapse;
                height: 2.5em;
            }
            .evenRow {
                background-color: #ffffff;
              }
              
              .oddRow {
                background-color: var(--background-light-grey);
              }
          }
          &::-webkit-scrollbar {
            width: 3px;
            background-color: #F5F5F5;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #888;
          }
        
          &::-webkit-scrollbar-thumb:hover {
            background-color: #555;
          }
    }
}



.switchButtons{
    display: flex;
    align-items: center;
    justify-content: center;
    .registeredButton{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 3em;
        cursor: pointer;
        background-color: var(--text-light-heading-fade);
        color: var(--background-black);
        box-shadow: 0px 2px 0px 0px black;

    }
    .requestedButton{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        height: 3em;
        cursor: pointer;
        background-color: var(--text-light-grey);

    }
}

.classDescription{
    background-color: var(--background-light-grey);
    text-align: left;
    padding: 1em;
    margin: 0 0 1em 0;
    h4{
        font-size: 2em;
        font-weight: 600;
    }
    p{
        margin: 1em 0em;
    }
}

.shareProfileModal{
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: 1em;
    min-width: 15em;
    input{
        margin: 0;
        width: 100%;
        border: 1px solid grey;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 0.5em 0.2em;
    }
    h1{
        font-weight: bold;
        font-size: 24px;
    }
    .renewButton{
        width: 100%;
        .modalContinueButton{
            width: 100%;
        }
    }
}

@media only screen and (min-width: 1000px) {
    .classDetailsMain{
        display: flex;
        flex-direction: column;
        justify-content: center;
        .desktopViewDiv{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            gap: 2em;
            .classInformation{
                display: flex;
                flex-direction: column;
                width: 100%;
                .classCardDetails{
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                }
                .tableDateCompHeader{
                    h1{
                        font-size: 1.8em;

                    }
                }

            }
            .studentsTableSection{
                display: flex;
                flex-direction: column;
                width: 100%;
            }
        }
    }
  }