.appPrimaryFillButton{
    // border: 2px solid var(--background-dark-grey);
    background-color: #942BF1;
    padding: 0.5em 0;
    cursor: pointer;
    color: var(--text-light-heading-fade);
    display: flex;
    justify-content: center;
    align-items: center;
    gap:1em;
    position: relative;
    height: 3em;
    width: 100%;
    border-radius: 8px;
    .imageComponent{
        position: relative;
        width: 100%;
        height: 100%;
        img{
            width: 100%;
            height:100%;
        }
    }
}

.appPrimaryFillButton:hover{
    padding: 0.5em 0;
    border: 2px solid var(--background-dark-grey);
    color: var(--text-dark-grey);
    background-color: white;
    border-radius: 8px;
}

.appPrimaryButton{
    border: 2px solid var(--background-dark-grey);
    background-color: white;
    padding: 0.5em 0;
    cursor: pointer;
    color: var(--text-dark-grey);
    display: flex;
    justify-content: center;
    align-items: center;
    gap:1em;
    position: relative;
    height: 3em;
    width: 100%;
    border-radius: 8px;
    .imageComponent{
        position: relative;
        height: 100%;
        img{
            width: 100%;
            height:100%;
        }
    }
}

.appPrimaryButton:hover{
    background-color: var(--background-dark-grey);
    color:var(--text-light-heading-fade)
}