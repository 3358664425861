.userProfile{
    h2{
        font-size: 2em;
    }
    .statColumn{
        p{
            font-weight: 600;
        }
        .namasteDiv{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.5em;
            p{
                font-size: 2em;
                font-weight: 600;
            }
            div{
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                gap: 1em;
            }
            
        }
        .registerRequestButtons{
            display: flex;
            justify-content: space-between;
            flex: 2;
            margin: 1em 0;
            gap: 1em;
            a{
                width: 100%;
            }
        }
        .statisticsTwoDivs{
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            gap: 1em;
            margin: 1em 0;
    
            
        }
        h2{
            margin-top:1em
        }
    }
}
.viewDropSeriesButtons{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1em;
    margin: 1em 0 0 0;
    a{
        width: 100%;
    }
}
.shareProfileModal{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 18em;
    // background-color: red;
    // height: 30vh;
    input{
        margin: 0 0.5em;
        width: 100%;
        border: 1px solid grey;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 0.5em 0.2em;
    }
    .miniRadioButtons{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 1em;
        margin-bottom: 1em !important;
        .selectOption{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            
            div{
                display: flex;
                justify-content: center;
                // align-items: center;
                flex-direction: column;
                width: 100%;
        }
        
        }
        .content{
            width: 100%;
            input{
                margin: 0;
                width: 100%;
                border: 1px solid grey;
                box-sizing: border-box;
                border-radius: 5px;
                padding: 0.5em 0.2em;
            }
        }
        .selectTypeText{
            width: 100%;
            font-size: 1.2em;
            text-align: left;
            // align-items: flex-start;
            margin-top: 1em;
        }

    }
    h1{
        font-weight: bold;
        font-size: 24px;
    }
    .renewButton{
        width: 100%;
        .modalContinueButton{
            width: 100%;
        }
    }
}


@media only screen and (max-width: 540px) {
    .userProfile{
        .statColumn{
            .namasteDiv{
                p{
                    font-size: 1.4em;
                    margin: 0.5em 0;
                }
            }
            .embedButtonDiv{
                width: 100%;
                button{
                    width: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width: 1140px) {
    .userProfile{
        // background-color: red;
        justify-content: center;
        .userIllustration{
            width: 100%;
            max-width: 37em;
        }
    }
}
@media only screen and (min-width: 1140px) {
    .userProfile{
        max-width: 80em;
        margin:auto;
        padding:0 2em;
        // position: relative;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row-reverse;
        justify-content: space-between;
            .userIllustration{
                min-width: 27em;
            }
            .statColumn{
                min-width: 35em;
                p{
                    font-size: 1.3em;
                }
                h2{
                    font-size: 2.5em;
                    font-weight: 600;
                    text-align: left;
                    margin: 0.5em 0 0 0;
                }
                
            }
            .namasteDiv{
                p{
                    font-size: 3em;
                }
                .icons{
                    div{
                        cursor: pointer;
                    }
                }
            }
        
    }
  }






