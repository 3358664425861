.appTimePicker{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 2;
    .cCsQJP {
        align-self: center;
      }
    
}