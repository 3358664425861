.modalwrapper{
    position: fixed;
    left:0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(54, 54, 54, 0.9);
    z-index: 100;
}

.modalButton{
    button{
        width: 100%;
    }
}
.modalcontainer{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 1em;
    border-radius: 1em;
    position: fixed;
    max-width: 30rem;
    // min-width: 15em;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: white;
    z-index: 200;
    .modalHeader{
        display: flex;
        align-items: center;
        width: 100%;
        h1{
            font-size: 1.5em;
            font-weight: bold;
        }
        .closeButton{
            cursor: pointer;
            img{
                width: 1em;
            }
        }
    }
}